/** @format */

import './style.css';
import 'antd/dist/reset.css';
import 'ckeditor5/ckeditor5.css';
import enUS from 'antd/lib/locale/en_US';
import ReactDOM from 'react-dom/client';
import {mainRoutes} from 'routes/router';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {QueryClientProvider} from '@tanstack/react-query';
import {ConfigProvider as AntdThemesProvider} from 'antd';
import {Provider as ReduxProvider} from 'react-redux';
import {graphQlClient} from 'graphql/connect';
import {ApolloProvider} from '@apollo/client';
import {queryClient} from 'utils/queries';
import {THEME_ANTD} from 'themes/color';
import {store} from 'stores';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const router = createBrowserRouter(mainRoutes);

root.render(
  <ReduxProvider store={store}>
    <AntdThemesProvider theme={THEME_ANTD} locale={enUS}>
      <ApolloProvider client={graphQlClient}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </ApolloProvider>
    </AntdThemesProvider>
  </ReduxProvider>,
);
